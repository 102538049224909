import { sample } from '@grrr/utils';
import { matchesBreakpoint } from './responsive';

const SIDES = [
  'front',
  'right',
  // 'back',
  'left',
  'top',
  'bottom',
];

const getUniqueRandomSide = side => {
  const random = sample(SIDES);
  return random === side ? getUniqueRandomSide(side) : random;
};

const rotate = logo => e => {
  const current = logo.getAttribute('data-side');
  logo.setAttribute('data-side', getUniqueRandomSide(current));
  logo.setAttribute('data-transitioning', 'true');
  window.setTimeout(() => {
    logo.setAttribute('data-transitioning', 'false');
  }, 400);
};

export const enhancer = logo => {
  // if (logo.getAttribute('data-autorotate') === 'true') {
  //   window.setInterval(rotate(logo), 3000);
  // }
  if (matchesBreakpoint('small')) {
    logo.addEventListener('mouseenter', rotate(logo));
    logo.addEventListener('mouseleave', rotate(logo));
  } else {
    logo.addEventListener('click', rotate(logo));
  }
};
