import { htmlToElement } from '@grrr/utils';

export const handler = (button, e) => {
  e.preventDefault();
  const email = atob(button.getAttribute('data-email'));
  button.parentNode.appendChild(htmlToElement(`
    <p><strong><a href="mailto:${email}">${email}</a></strong></p>
  `));
  button.remove();
};
