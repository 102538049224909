// Import libraries and polyfills
import Promise from 'promise-polyfill';
import 'whatwg-fetch';
import 'focus-visible';
import 'classlist-polyfill';
import { handle, enhance } from '@grrr/hansel';
import rafPolyfill from './polyfills/request-animation-frame';

// Import functions that are executed on DOMready regardless of DOM
import { onDomReady } from './modules/ready';
import { listener as mouseListener } from './modules/mouse-listener';
import { listener as scrollListener } from './modules/scroll-listener';
import { enhancer as responsive } from './modules/responsive';
import { default as disableHoverStylesOnScroll } from './modules/disable-hover-styles-on-scroll';

// Import handlers
import { handler as classToggler } from './modules/class-toggler';
import { handler as cookieBarAccept } from './modules/cookie-bar';
import { handler as emailReveal } from './modules/email-reveal';
import { handler as gtmEventHandler } from './modules/gtm-event';
import { handler as randomPage } from './modules/random-page';

// Import enhancers
import { enhancer as cookieBar } from './modules/cookie-bar';
import { enhancer as frontPage } from './modules/front-page';
import { enhancer as frontPageArrow } from './modules/front-page-arrow';
import { enhancer as gtmEventEnhancer } from './modules/gtm-event';
import { enhancer as newsletterSignup } from './modules/newsletter-signup';
import { enhancer as particles } from './modules/particles';
import { enhancer as siteLogo } from './modules/site-logo';
import { enhancer as siteNav } from './modules/site-nav';
import { enhancer as test } from './modules/test';
import { enhancer as workTitle } from './modules/work-title';

const executeOnReady = () => {
  disableHoverStylesOnScroll();
  mouseListener();
  scrollListener();
  responsive();

  window.requestAnimationFrame = window.requestAnimationFrame || rafPolyfill;

  if (typeof window.Promise === 'undefined') {
    window.Promise = Promise;
  }
};

const main = () => {
  executeOnReady();
  handle(document.documentElement, {
    classToggler,
    cookieBarAccept,
    emailReveal,
    gtmEventHandler,
    randomPage,
  });
  enhance(document.documentElement, {
    cookieBar,
    frontPage,
    frontPageArrow,
    gtmEventEnhancer,
    newsletterSignup,
    particles,
    siteLogo,
    siteNav,
    test,
    workTitle,
  });
};

onDomReady(main);
