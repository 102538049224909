import { matchesBreakpoint } from './responsive';
import { register as registerScrollListener } from './scroll-listener';
import { getScrollPosition } from './util';

export const enhancer = el => {

  if (!matchesBreakpoint('small')) {
    return;
  }

  const paint = () => {
    const y = getScrollPosition().y;
    el.style.transform = `translate3d(-${(y * 0.075)}px, 0, 0)`;
  };

  const scrollHandler = e => requestAnimationFrame(paint);

  registerScrollListener(el, scrollHandler);
  paint();

};
