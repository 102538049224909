import { matchesBreakpoint } from './responsive';
import { register as registerScrollListener } from './scroll-listener';
import { getScrollPosition } from './util';

export const enhancer = el => {

  if (!matchesBreakpoint('small')) {
    return;
  }

  const paint = () => {
    const y = getScrollPosition().y;
    el.style.transform = `translate3d(0, ${y * 1.25}px, 0)`;
  };

  const scrollHandler = e => requestAnimationFrame(paint);

  registerScrollListener(el, scrollHandler);

};
