export const enhancer = container => {

  const clickHandler = e => {
    const hash = e.currentTarget.hash;
    if (!hash) {
      return;
    }
    const target = document.querySelector(hash);
    if (target) {
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      e.preventDefault();
    }
  };

  const links = [...container.querySelectorAll('a')];
  links.forEach(link => link.addEventListener('click', clickHandler));

};
