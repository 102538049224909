import { matchesBreakpoint } from './responsive';
import { register as registerMouseListener } from './mouse-listener';

const Particles = particles => {
  const particleArray = [...particles].map(particle => ({
    node: particle,
    x: particle.getBoundingClientRect().left,
    y: particle.getBoundingClientRect().top,
  }));

  const mouseMoveHandler = (x, y) => {
    const move = () => {
      particleArray.forEach(particle => {
        const newX = x > particle.x
          ? (x / (particle.x))
          : (-x / (particle.x));
        const newY = y > particle.y
          ? (y / (particle.y))
          : (-y / (particle.y));

        particle.node.style.transform = `translate(${newX}px, ${newY}px)`;
      });
    };
    requestAnimationFrame(move);
  };

  if (matchesBreakpoint('small')) {
    registerMouseListener('particles', e => {
      mouseMoveHandler(e.clientX * 5, e.clientY * 5);
    });
  } else {
    window.addEventListener('deviceorientation', e => {
      mouseMoveHandler(e.alpha * 10, e.beta * 200);
    });
  }
};

export const enhancer = container => {
  if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
    return;
  }

  Particles(container.children);
};
