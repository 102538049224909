import { throttle } from '@grrr/utils';
import { register as registerMouseListener } from './mouse-listener';

// const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

// const getRandomFloat = (min, max) => Math.random() * (max - min) + min;

export const enhancer = container => {
  const image = container.querySelector('img');

  const mouseMoveHandler = e => {
    const animate = () => {
      const x = (e.clientX / 25) * Math.random();
      const y = (e.clientY / 25) * Math.random();
      image.style.filter = `invert(${Math.round(Math.random())})`;
      image.style.transform = `translate3d(${x}px, ${y}px, 0)`;
    };
    requestAnimationFrame(animate);
  };

  registerMouseListener('test-image', throttle(mouseMoveHandler, 100));
};
