const listeners = {};

const moveHandler = e => {
  Object.keys(listeners).forEach(key => {
    if (typeof listeners[key] === 'function') {
      listeners[key](e);
    }
  });
};

export const listener = () => {
  window.addEventListener('mousemove', moveHandler);
};

export const register = (key, fn) => {
  listeners[key] = fn;
};
